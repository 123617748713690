// This component merges both styles and snackbar - this aint optimal and prevents some error managing
// TODO: separate from Snackbar in order to manage fetch errors
import React, {useEffect, useMemo, useState} from "react"
import {ThemeProvider} from '@emotion/react';
import {SnackbarProvider} from "notistack";
import {createTheme} from "@mui/material/styles";
import {useUser} from "./UserProvider";
import {APIGet} from "../api/calls";

const {REACT_APP_DASHBOARD_API_URL} = process.env

const colors = {
    primary: "#b4c74a",
    secondary: "#6192bd",
}

const stylesInit = {
    primaryColor: colors.primary,
    secondaryColor: colors.secondary,
}

// This is a simplified version of the theme provider from the dashboard
// We don't use the logo attribute and simply fetch information from the main API to dispatch custom colors if present
export default function UIProvider(props: any) {

    const {user, token} = useUser()
    const [primaryColor, setPrimaryColor] = useState(stylesInit.primaryColor);
    const [secondaryColor, setSecondaryColor] = useState(stylesInit.secondaryColor);

    const theme = useMemo(() => createTheme({
        palette: {
            primary: {
                main: primaryColor,
                contrastText: "#ffffff",
            },
            secondary: {
                main: secondaryColor,
                contrastText: "#ffffff",
            },
            text: {
                secondary: "#525252",
            }
        },
        typography: {
            h1: {
                fontFamily: 'Roboto',
                fontSize: 48,
                lineHeight: "58px",
                letterSpacing: "0.00938em",
                fontWeight: 900,
            },
            h2: {
                fontSize: 24,
                lineHeight: '34px',
                fontWeight: 400,
            },
            h5: {
                fontWeight: '500',
            }
        }
    }), [primaryColor, secondaryColor]);

    useEffect(() => {
        if (!!user) fetchStylesData()
    }, [user]);

    const fetchStylesData = () => {
        APIGet(REACT_APP_DASHBOARD_API_URL + "/user/setting", token).then((res: any) => {
            if (!!res.parsedBody && !!res.parsedBody.dash_design) {
                let _styles = res.parsedBody.dash_design
                setPrimaryColor(_styles.primaryColor)
                setSecondaryColor(_styles.secondaryColor)
            }
        }).catch(() => {
            // handleError(t("settings:error_fetching_styles"))
        }).finally(() => {
            // setLoading(false)
            // setStylesDidLoad(true)
        })
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    {props.children}
                </SnackbarProvider>
            </ThemeProvider>
        </>
    )
}