import React, { useState, useEffect } from "react"
import {Link} from "react-router-dom";
// MUI
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography, Alert, Collapse, MenuItem
} from "@mui/material";
import {
    Add, Delete,
    Edit, FilterAlt, FilterAltOff, InfoRounded,
    RememberMeOutlined,
    Visibility
} from "@mui/icons-material";
// Primereact
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
// Components
import {Paginator, Select, TextField, Loading} from "../../utils";
import {DeleteVcardDialog} from "../../Dialogs";
// Translation
import {useTranslation, Trans} from "react-i18next";
// CSS
import "./styles.css"
import {styles} from "./styles"
// Queries
import {useLazyQuery, useMutation} from "@apollo/client";
import {CREATE_TEMPLATE, DELETE_VCARD, GET_DEPARTMENTS, GET_TEMPLATES, GET_VCARDS} from "../../../api/calls";
// Provider
import {useUser} from "../../../provider/UserProvider";
import {useModule} from "../../../provider/ClientModuleProvider";
// Types
import {VcardTypes, TemplateTypes} from "../../../types";
import returnDefaultTemplate from "../../../functions/returnDefaultTemplate";


const {REACT_APP_WEBAPP_URL} = process.env

export default function VcardPanel(props: any) {

    const {t} = useTranslation(["common"])
    const {isMember} = props
    const {user, userRoles, credits, productCredits, canCreate} = useUser()
    const {usingModule, usingProducts} = useModule()
    let creditsLeft = credits.credits_left
    let userType = user.organisation_role
    // Vcards state
    const [vcards, setVcards] = useState<VcardTypes.VcardList[] | undefined>([])
    // Departments and Templates names
    const [departments, setDepartments] = useState<any>([])
    const [templates, setTemplates] = useState<TemplateTypes.Template[] | undefined>([])
    const [productTemplates, setProductTemplates] = React.useState<any>([])
    const [businessTemplates, setBusinessTemplates] = React.useState<any>([])
    // Loadings and timeouts
    const [creatingDefaultTemplate, setCreatingDefaultTemplate] = React.useState<boolean>(false)
    // Dialogs
    const [deletionDialog, setDeletionDialog] = useState<{ open: boolean, uuid: string }>({ open: false, uuid: "" })
    // Filters
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [perPage, setPerPage] = useState<number>(10)
    const [isLastPage, setIsLastPage] = useState<boolean>(false)
    const [filters, setFilters] = useState<any>({
        name: "",
        department: "",
        template: "",
        email: ""
    })

    // Queries & Mutations
    const [getTemplatesCreated, {
        loading: templatesLoading,
        error: templatesError,
    }] = useLazyQuery(GET_TEMPLATES, {
        fetchPolicy: "no-cache"
    })

    const [saveNewTemplate, {
        loading: saveNewTemplateLoading,
    }] = useMutation(CREATE_TEMPLATE)

    const [getDepartments, {
        loading: departmentsLoading,
        error: departmentsError,
    }] = useLazyQuery(GET_DEPARTMENTS, { fetchPolicy: "no-cache" })

    const [getVcardsList, {
        data: vcardsListData,
        loading: vcardsListLoading,
        error: vcardsListError
    }] = useLazyQuery(GET_VCARDS, {
        fetchPolicy: "no-cache"
    })

    const [deleteVcard, {
        loading: deletionLoading,
        error: deletionError
    }] = useMutation(DELETE_VCARD, { errorPolicy: 'ignore' })

    useEffect(() => {
        if (user) {
            // First, fetch vcards
            fetchVcards()
            // Then fetch other data to fill up filters
            fetchDepartments()
            fetchTemplates()
        }
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    useEffect(() => {
        if (vcardsListData) {
            if (isMember) {
                setVcards(vcardsListData.getVcards.values.filter((vcard: any) => (vcard.user.email === user.email || vcard.ownerUuid === user.uuid)))
                return
            }
            setVcards(vcardsListData.getVcards.values)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vcardsListData])

    useEffect(() => {

        if (vcardsListData) {
            let vcardsDisplayed = pageNumber * perPage
            if (vcardsListData.getVcards.total === undefined || vcardsDisplayed >= vcardsListData.getVcards.total) {
                setIsLastPage(true)
            } else {
                setIsLastPage(false)
            }
        }
    }, [pageNumber, vcardsListData, perPage])

    React.useEffect(() => {
        if (!!templates && templates.length >= 1) {
            let _productTemplates = templates.filter((template: any) => template.templateType === "PRODUCT")
            if (_productTemplates[0]) setProductTemplates(_productTemplates)
            let _businessTemplates = templates.filter((template: any) => template.templateType === "BUSINESS")
            if (_businessTemplates[0]) setBusinessTemplates(_businessTemplates)
        }
    }, [templates])

    useEffect(() => {
        if (creatingDefaultTemplate) {
            createDefaultTemplate()
        }
    }, [creatingDefaultTemplate])

    // Refresh on page change
    useEffect(() => {
        fetchVcards()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, perPage])

    const fetchVcards = () => {
        getVcardsList({
            variables: {
                page: pageNumber,
                perPage: perPage,
            }
        }).then()
    }

    const createDefaultTemplate = () => {

        let defaultTemplate = returnDefaultTemplate(t("default_template"), usingProducts)

        saveNewTemplate({ variables: { newTemplate: { ...defaultTemplate }}})
            .finally(() => {
                setCreatingDefaultTemplate(false)
                fetchTemplates()
            })
    }

    // TODO: add templates list pagination
    const fetchTemplates = () => {
        getTemplatesCreated({
            variables:
                {
                    page: 1,
                    perPage: 100
                },
            fetchPolicy: "no-cache"
        }).then((res) => {
            if (res.data.getTemplates.values.length === 0) {
                if (userType !== "MEMBER" && usingModule.module !== "bouygues") {
                    setCreatingDefaultTemplate(true)
                }
            } else setTemplates(res.data.getTemplates.values)
        })
    }

    // TODO: add department pagination
    const fetchDepartments = () => {
        getDepartments({
            variables:
                {
                    page: 1,
                    perPage: 10
                }
        }).then((res) => setDepartments(res.data.getDepartments.values))
    }

    const handleDelete = (contactHolder: string | undefined) => {
        let {uuid} = deletionDialog

        let body= {
            variables: {
                vcardUuids: [uuid],
                newContactHolder: !!contactHolder ? contactHolder : "",
            }
        }

        deleteVcard(body)
            .then(() => {
                setDeletionDialog({ open: false, uuid: "" })
                fetchVcards()
                return
            })
        // Finally, reset deletion dialog
        setDeletionDialog({ open: false, uuid: "" })
    }

    const dataTablePhotoBody = (rowData: any) => {
        return <Avatar src={rowData.user.picture} sx={styles.avatar}><RememberMeOutlined /></Avatar>
    }

    const dataTableNameAndPermissionsBody = (rowData: any) => {
        return (
            <>
                <Typography variant={"body2"}>
                    {rowData.user.firstName} {rowData.user.lastName}
                </Typography>
            </>
        )
    }

    const dataTableDepartmentBody = (rowData: any) => {
        let _department

        if (!departments || departments.length < 1 || !rowData.user.departmentUuid || rowData.user.departmentUuid === "") {
            return <></>
        } else {
            _department = departments.find((e: any) => e.uuid === rowData.user.departmentUuid)
            if (_department) {
                return (
                    <Typography>
                        {_department.name}
                    </Typography>
                )
            }
            return (
                <Typography color={"error"} variant={"body2"}>
                    <Tooltip title={t("department_not_found_text")} placement={"top"} arrow>
                        <InfoRounded color={"error"} sx={{ fontSize: 14, mb: "-2px" }} />
                    </Tooltip>
                    &nbsp;
                    {t("department_not_found")}
                </Typography>
            )
        }
    }

    const dataTableTemplateBody = (rowData: any) => {

        let _template: TemplateTypes.TemplateListElement

        if (!templates || templates.length < 1) {
            return <></>
        } else {
            _template = templates.find((e: any) => e.uuid === rowData.templateUuid) as unknown as TemplateTypes.TemplateListElement
            if (_template) {
                return (
                    <Typography>
                        {_template.name}
                    </Typography>
                )
            }
            return (
                <Typography color={"error"} variant={"body2"}>
                    <Tooltip title={t("template_not_found_text")} placement={"top"} arrow>
                        <InfoRounded color={"error"} sx={{ fontSize: 14, mb: "-2px" }} />
                    </Tooltip>
                    &nbsp;
                    {t("template_not_found")}
                </Typography>
            )
        }
    }

    const dataTableEmailBody = (rowData: any) => {
        return <Typography>{rowData.user.email}</Typography>
    }

    const dataTableActionBody = (rowData: any) => {

        const returnEditionUrl = (rowData: any) => {

            // specific bypâss for bouygues members
            if (usingModule.module === "bouygues" || (!!templates && templates.length < 1)) return `/vcard/create?id=${rowData.uuid}`

            let isUsingProduct = templates?.filter((template: any) => template.uuid === rowData.templateUuid)
            if (!!isUsingProduct && !!isUsingProduct[0].templateType && isUsingProduct[0].templateType === "PRODUCT") return `/vcard/create?id=${rowData.uuid}&type=product`
            return `/vcard/create?id=${rowData.uuid}`
        }

        return (
            <Grid container justifyContent={"flex-start"} spacing={1}>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={t("see_vcard")}>
                        <IconButton
                            size={"small"}
                            component={"a"}
                            target={"_blank"}
                            rel={"noopener noreferrer"}
                            href={`${REACT_APP_WEBAPP_URL}/${rowData.uuid}`}
                        >
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Tooltip placement={"top"} arrow title={t("edit")}>
                        <IconButton size={"small"} component={Link} to={returnEditionUrl(rowData)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
                {
                    (userType === userRoles.admin || userType === userRoles.owner) &&
                    <Grid item>
                        <Tooltip placement={"top"} arrow title={t("delete")}>
                            <IconButton size={"small"} onClick={() => { setDeletionDialog({ open: true, uuid: rowData.uuid })}}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </Grid>
        )
    }

    const renderFiltersSection = () => {

        const returnClearFiltersButton = () => {
            return (
                <Button
                    onClick={() => clearFilters()}
                    startIcon={<FilterAltOff />}
                    color={"secondary"}
                    variant={"contained"}
                    disableElevation
                    size={"small"}
                >
                    {t("clear_filters")}
                </Button>
            )
        }

        if (templates && templates.length > 0 && !templatesLoading) {
            return (
                <Grid item xs={12}>
                    {/*<Button*/}
                    {/*    onClick={() => setFilterAccordion(!filterAccordion)}*/}
                    {/*>*/}
                    {/*    {t("filters_section")}*/}
                    {/*</Button>*/}
                    <Collapse in={true}>
                        <Grid container justifyContent={"flex-start"} spacing={1} columns={14}>
                            <Grid item xs={14}>
                                {t("filters_section")}
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    fullWidth
                                    size={"small"}
                                    label={t("name")}
                                    value={filters.name}
                                    onChange={(e: any) => setFilters({...filters, name: e.target.value})}
                                />
                            </Grid>
                            {
                                departments &&
                                <Grid item xs={12} md={6} lg={3}>
                                    <Select
                                        label={t("department")}
                                        size={"small"}
                                        value={filters.department}
                                        onChange={(e: any) => setFilters({...filters, department: e.target.value})}
                                    >
                                        <MenuItem value={""}>{t("no_department_selected")}</MenuItem>
                                        {departments && departments.map((department: any, index: number) => (
                                            <MenuItem value={department.uuid} key={index}>{department.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            }
                            {
                                templates &&
                                <Grid item xs={12} md={6} lg={3}>
                                    <Select
                                        label={t("template")}
                                        size={"small"}
                                        value={filters.template}
                                        onChange={(e: any) => setFilters({...filters, template: e.target.value})}
                                    >
                                        <MenuItem value={""}>{t("no_template_selected")}</MenuItem>
                                        {templates && templates.map((template: any, index: number) => (
                                            <MenuItem value={template.uuid} key={index}>{template.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            }
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    fullWidth
                                    size={"small"}
                                    label={t("email")}
                                    value={filters.email}
                                    onChange={(e: any) => setFilters({...filters, email: e.target.value})}
                                />
                            </Grid>
                            <Grid item xs={14} md={2} textAlign={"right"}>
                                <Button
                                    onClick={() => filter()}
                                    startIcon={<FilterAlt />}
                                    size={"small"}
                                    variant={"contained"} disableElevation
                                >
                                    {t("filter")}
                                </Button>
                            </Grid>
                            <Grid item xs={14}>
                                <Collapse in={!!filters.name || !!filters.email || !!filters.department || !!filters.template}>
                                    <Alert severity={"info"} action={returnClearFiltersButton()} sx={{ py: 2 }}>
                                        {t("filters_are_active")}
                                    </Alert>
                                </Collapse>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>
            )
        }
    }

    const clearFilters = () => {
        setFilters({
            name: "",
            department: "",
            template: "",
            email: ""
        })

        fetchVcards()
    }

    const filter = () => {

        let query: any = {}
        let filtersLength: number = 0
        let activeFilters: any = {}

        Object.keys(filters).forEach((key: any) => {
            if (filters[key] !== "") {
                filtersLength++
                activeFilters = {...activeFilters, [key]: filters[key]}
            }
        })

        const returnDataKey = (dataName: any) => {
            switch (dataName) {
                case "name": return "user.fullName"
                case "department": return "departmentUuid"
                case "template": return "templateUuid"
                case "email": return "user.email"
            }
        }

        const returnOperator = (dataName: any) => {
            switch (dataName) {
                case "department": return "Eq"
                case "template": return "Eq"
                default: return "Contains"
            }
        }

        if (filtersLength === 0 || !activeFilters) {
            clearFilters()
            return
        } else if (filtersLength === 1) {
            query = {
                type: "Value",
                value: {
                    type: returnOperator(Object.keys(activeFilters)[0]),
                    key: returnDataKey(Object.keys(activeFilters)[0]),
                    value: Object.values(activeFilters)[0]
                }
            }
        } else if (filtersLength > 1) {

            let _and: any[] = []

            Object.keys(activeFilters).forEach((key: any) => {
                _and.push({
                    type: "Value",
                    value: {
                        type: returnOperator(key),
                        key: returnDataKey(key),
                        value: activeFilters[key]
                    }
                })
            })

            query = {
                type: "And",
                and: _and
            }
        }

        getVcardsList({
            variables: {
                page: 1,
                perPage: perPage,
                filter: query
            }
        }).then()

    }

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Grid container justifyContent={{ xs: "center" }} rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h4"} component={"p"}>
                            {t("vcards_list")}
                        </Typography>
                        <Typography>
                            {t("vcards_list_intro")}
                        </Typography>
                    </Grid>
                    <Grid item xs={"auto"} md={12}>
                        {
                            (!usingModule || usingModule.module !== "bouygues") &&
                                <>
                                    <Button
                                        variant={"contained"}
                                        disableElevation
                                        startIcon={<Add />}
                                        component={Link}
                                        to={"/vcard/create"}
                                        disabled={businessTemplates?.length < 1 || creditsLeft < 1 || !canCreate}
                                        sx={{ mr: 1 }}
                                    >
                                        {t("vcards_create")}
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        disableElevation
                                        startIcon={<Add />}
                                        component={Link}
                                        to={"/vcard/create?type=product"}
                                        disabled={
                                            !usingProducts ||
                                            !canCreate ||
                                            productCredits.credits_left < 1 ||
                                            productTemplates?.length < 1
                                        }
                                    >
                                        {t("create_product_vcard")}
                                    </Button>
                                </>
                        }

                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    {renderFiltersSection()}

                    {

                        (templatesLoading || vcardsListLoading || departmentsLoading || saveNewTemplateLoading || creatingDefaultTemplate) ?
                            <Loading />
                        : (templatesError || vcardsListError || departmentsError) ?
                            <Grid item xs={12}>
                                <Alert severity={"error"}>
                                    {t("vcards_loading_error")}
                                </Alert>
                            </Grid>
                        : ((templates && templates.length > 0 && !templatesLoading) || isMember) ?
                            <>
                                <Grid item xs={12} className={"vcardsListDataTable"}>
                                    <Paper variant={"outlined"}>
                                        <DataTable
                                            value={vcards}
                                            breakpoint={"1103px"}
                                            emptyMessage={t("vcards_list_empty_message")}
                                            rows={10}
                                            sortField={"user.lastName"}
                                        >
                                            <Column
                                                header={t("vcard_photo")}
                                                body={dataTablePhotoBody}
                                            ></Column>
                                            <Column
                                                body={dataTableNameAndPermissionsBody}
                                                header={t("name")}
                                                sortable
                                                sortField={"user.lastName"}
                                            ></Column>
                                            <Column
                                                body={dataTableDepartmentBody}
                                                header={t("department")}
                                                sortable
                                                sortField={"user.departmentUuid"}
                                            ></Column>
                                            <Column
                                                body={dataTableTemplateBody}
                                                field={"template"}
                                                header={t("template")}
                                                sortable
                                                sortField={"templateUuid"}
                                                style={{ display: isMember ? "none" : ""}}
                                            ></Column>
                                            <Column
                                                body={dataTableEmailBody}
                                                header={t("vcard_user")}
                                                sortable
                                                sortField={"user.email"}
                                            ></Column>
                                            <Column
                                                body={dataTableActionBody}
                                                header={t("actions")}
                                            ></Column>
                                        </DataTable>
                                    </Paper>
                                </Grid>

                                <Paginator {...{perPage, setPerPage, pageNumber, setPageNumber, isLastPage, t}} />
                            </>
                        : (isMember) ?
                            <Grid item xs={12}>
                                <Alert severity={"warning"}>
                                    {t("vcards_error_no_vcards_created")}
                                </Alert>
                            </Grid>
                        :
                            <Grid item xs={12}>
                                <Alert severity={"warning"}>
                                    <Trans i18nKey={"vcards_error_no_templates_created"} t={t}>
                                        eee<Link to={"/?tab=design"} className={"link"}>eee</Link>eee
                                    </Trans>
                                </Alert>
                            </Grid>
                    }
                </Grid>

                <DeleteVcardDialog
                    open={deletionDialog.open}
                    toDelete={deletionDialog.uuid}
                    onClose={() => setDeletionDialog({ open: false, uuid: "" })}
                    onDelete={(contactHolder: string | undefined) => handleDelete(contactHolder)}
                    {...{deletionError, deletionLoading, t}}
                />
            </Box>
        </>
    )
}
